import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>IVAnalizer</h1>
        <p>To help you get started, we have set up:</p>
        <ul>
          <li><strong>Pending Measurements</strong>. 
          Here we can see any measurement collected from the devices and not yet accepted. 
          We should be able to see the date, name(filename), type, and can add summary for the pending measurement. Also we could see the measurement IV Curve. 
          Once we add the summary, we can accept the measurement and it will be moved to the Valid Measurements page.
          </li>
          <li><strong>Valid Measurements</strong>. 
          In the valid measurements page we can see all the measurements that were accepted.
          We should be able to see the date, name(filename), type, summary, and can visualize the measurement IV Curve.
          We can select a measurement and it will be drawn on the graph.
          In the next version we can also export all the selected measurements to a SensiChips measurement file.
          </li>
        </ul>
      </div>
    );
  }
}
