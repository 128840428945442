// Purpose: This file contains the code for the ValidMeasurements component. This component is used to display the IV curve of the selected measurements.        
import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import ReactPaginate from 'react-paginate';
import { Modal, Form, Button } from 'react-bootstrap';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class ValidMeasurements extends Component {
  static displayName = ValidMeasurements.name;

  constructor(props) {
    super(props);
    this.state = {
      measurements: [],
      loading: true,
      currentPage: 0,
      selectedMeasurements: [],
      modalIsOpen: false,
      testModalIsOpen: false,
      selectedModelType: '',
      labelObjects: []
    };
  }

  componentDidMount() {
    this.populateMeasurementsData();
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    this.setState({ currentPage: selected });
  }

  formatDate = (isoString) => {
    const date = new Date(isoString);
    const dateString = date.toLocaleDateString('en-GB');
    const timeString = date.toLocaleTimeString('en-GB', { hour12: false });
    return `${dateString} ${timeString}`;
  };

  renderMeasurementsTable(measurements, handleClick) {
    const perPage = 10;
    const offset = this.state.currentPage * perPage;
    const currentPageData = measurements.slice(offset, offset + perPage);

    return (
      <div>
        <div>
          <p>You have selected {this.state.selectedMeasurements.length} measurements</p>
          <Button
            onClick={() => this.openModal()}
            disabled={this.state.selectedMeasurements.length === 0}
            variant="primary"
          >
            Create Model
          </Button>
        </div>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Summary</th>
              <th>Label</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {currentPageData.map(measurement =>
              <tr key={measurement.date}>
                <td>{this.formatDate(measurement.date)}</td>
                <td>{measurement.name}</td>
                <td>{measurement.summary}</td>
                <td>{measurement.label}</td>
                <td>
                  <button className='btn btn-primary me-1' onClick={() => this.handleClick(measurement)}>{measurement.selected ? 'Un-Select' : 'Select'}</button>
                  <button className='btn btn-primary' onClick={() => this.openTestModal(measurement)}>Test</button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          pageCount={Math.ceil(measurements.length / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination justify-content-center'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
        />
      </div>
    );
  }
  openModal = () => {

    //create a list with all distinct labels from the selected measurements
    const labelObjects = this.GetLabelsFromSelectedMeasurements();
    this.setState({ modalIsOpen: true, labelObjects: labelObjects,selectedModelType:'binary', selectedLabel: labelObjects[0].labelname});
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  openTestModal = (measurement) => {

    //fetch test models from the database
    fetch('/api/Models')
      .then(response => response.json())
      .then(data => {
        this.setState({ testModels: data });
      })

    this.setState({ currentMeasurement: measurement, testModalIsOpen: true });
  }

  closeTestModal = () => {
    this.setState({ testModalIsOpen: false, testResults: ''});
  }


  handleClick = (measurement) => {
    measurement.selected = !measurement.selected;
    var selectedMeasurements = this.state.measurements.filter(measurement => measurement.selected);
    this.setState({ selectedMeasurements: selectedMeasurements, measurements: this.state.measurements });
  }

  GetLabelsFromSelectedMeasurements(data) {
    if (!data) {
      data = this.state.selectedMeasurements;
    }
    const selectedMeasurements = data;
    const distinctLabels = [...new Set(selectedMeasurements.map(measurement => measurement.label))];
    const labelCount = selectedMeasurements.reduce((acc, measurement) => {
      acc[measurement.label] = acc[measurement.label] ? acc[measurement.label] + 1 : 1;
      return acc;
    }, {});

    const labelObjects = distinctLabels.map(label => ({ labelname: label, count: labelCount[label] }));
    return labelObjects;
  }

  render() {
    const selectedMeasurements = this.state.selectedMeasurements;

    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: "IV Curve"
      },
      axisY: {
        title: "Current (uA)",
        zoomEnabled: true
      },
      axisX: {
        title: "Voltage (mV)",
        zoomEnabled: true
      },
      toolTip: {
        shared: true
      },
      data: selectedMeasurements.map(measurement => (
        {
          type: "spline",
          name: measurement.name,
          showInLegend: true,
          dataPoints: measurement.dataPoints
        })),
      legend: {
        cursor: "pointer",
        itemclick: function (e) {
          e.dataSeries.lineThickness = e.dataSeries.lineThickness === 1 ? 5 : 1;
          e.chart.render();
        }
      },
      height: 800
    };

    return (
      <div>
        <CanvasJSChart options={options} />
        {this.renderMeasurementsTable(this.state.measurements, this.handleClick)}
        {this.renderTestModal()}
        {this.renderCreateModelModal()}
      </div>
    );
  }
  renderCreateModelModal() {
    return (
      <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.CreateModel}>
            <Form.Group controlId="modelName">
              <Form.Label>Model Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Model Name" />
            </Form.Group>
            <Form.Group controlId="modelType">
              <Form.Label>Model Type</Form.Label>
              <Form.Control as="select" value={this.state.selectedModelType} onChange={(e) => this.setState({ selectedModelType: e.target.value })}>
                <option value="binary">Binary classification</option>
                <option value="multiple">Multi class classification</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="label">
              <Form.Label>Label</Form.Label>
              <Form.Control as="select" value={this.state.selectedLabel}  onChange={(e) => this.setState({ selectedLabel: e.target.value })}>
                {this.state.labelObjects.map(labelObject => <option key={labelObject.labelname} value={labelObject.labelname}>{labelObject.labelname}</option>)}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  renderTestModal() {
    if (!this.state.currentMeasurement) {
      return null;
    }
  
    return (
      <Modal show={this.state.testModalIsOpen} onHide={this.closeTestModal}>
        <Modal.Header closeButton>
          <Modal.Title>Test Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group className="mb-3">
              <Form.Label>Measurement Name:</Form.Label>
              <Form.Control type="text" ref={this.nameRef} defaultValue={this.state.currentMeasurement.name} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Summary:</Form.Label>
              <Form.Control type="text" ref={this.summaryRef} defaultValue={this.state.currentMeasurement.summary} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Label:</Form.Label>
              <Form.Control type="text" ref={this.summaryRef} defaultValue={this.state.currentMeasurement.label}  required/> 
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Test Model:</Form.Label>
              <Form.Select aria-label="Test Model"
                value={this.state.selectedModel} 
                onChange={e => this.setState({ selectedModel: e.target.value })}
              >
                {this.state.testModels && this.state.testModels.map(model => <option key={model.id}>{model.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Button variant="primary" onClick={this.TestModel} >
              Test
            </Button>
            <Form.Group className="mb-3">
              <Form.Label>Test Results:</Form.Label>
              <Form.Control as="textarea" rows={3} value={this.state.testResults} readOnly /> 
            </Form.Group>


            
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  TestModel = () => {
    //test the current measurement with the selected model
    const data = { FileName: this.state.currentMeasurement.name, ModelName: this.state.selectedModel, MeasurementType: "approved" };
    fetch('/api/Models/Predict', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        //store test results to be displayed in the modal text area
        this.setState({ testResults: data.message });
      
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    
  }

  CreateModel = (event) => {  
    event.preventDefault();
    debugger;
    const form = event.target;
    const modelName = form.modelName.value;
    const selectedModelType = this.state.selectedModelType;
    const selectedLabel = this.state.selectedLabel;
    const selectedMeasurements = this.state.selectedMeasurements.map(measurement => {
      return measurement.name;
    });
    const data = {
      Name: modelName,
      Type: selectedModelType,
      Label: selectedLabel,
      TrainingMeasurements: selectedMeasurements,
      TestMeasurements: selectedMeasurements,
    };
    fetch('api/Models/CreateModel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(() => {
      this.setState({ modalIsOpen: false });
    });
  }

  async populateMeasurementsData() {
    const response = await fetch('api/Measurements');
    const data = await response.json();

    if (data && data.length > 0) {
      data[0].selected = true;
    }
    // // add random 'water','air' label to the measurements
    // data.forEach(measurement => {
    //   measurement.label = Math.random() > 0.5 ? 'water' : 'air';
    // });
    var selectedMeasurements = data.filter(measurement => measurement.selected);
    //set the labels for the selected measurements
    const labelObjects = this.GetLabelsFromSelectedMeasurements(data);
    this.setState({ measurements: data, loading: false, labelObjects: labelObjects, selectedMeasurements: selectedMeasurements });
  }
}
