// Purpose: This file contains the code for the PendingMeasurements component. This component is used to display the IV curve of the selected measurements.        
import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import ReactPaginate from 'react-paginate';
import { Modal, Form, Button } from 'react-bootstrap';
import Notification from './Notification';


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class PendingMeasurements extends Component {
  static displayName = PendingMeasurements.name;

  constructor(props) {
    super(props);
    this.state = {
      measurements: [], loading: true, currentPage: 0, editModalIsOpen: false, testModalIsOpen: false, currentMeasurement: null
    };
    this.nameRef = React.createRef();
    this.summaryRef = React.createRef();
    this.labelRef = React.createRef();
    this.dateRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.notificationRef = React.createRef();
  }

  componentDidMount() {
    this.populateMeasurementsData();
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    this.setState({ currentPage: selected });
  }

  formatDate = (isoString) => {
    const date = new Date(isoString);
    const dateString = date.toLocaleDateString('en-GB');
    const timeString = date.toLocaleTimeString('en-GB', { hour12: false });
    return `${dateString} ${timeString}`;
  };

  accept = (measurement) => {
    const index = this.state.measurements.findIndex(m => m.name === measurement.name);
    if(measurement.label === '' || measurement.label === undefined){
      this.notificationRef.current.showNotification('Label is required!','danger');
      return;
    }
    if (index !== -1) {
      //push selected measurement to the database
      const data = { FileName: this.state.measurements[index].name };
      fetch('/api/Measurements', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          //remove measurement from pending measurements
          this.setState(prevState => ({
            measurements: [
              ...prevState.measurements.slice(0, index),
              ...prevState.measurements.slice(index + 1)
            ]
          }))
        })
        .then(() => this.setState(prevState => {
          // Check if a measurement is already selected
          const alreadySelected = prevState.measurements.some(measurement => measurement.selected);
          if (!alreadySelected && prevState.measurements.length > 0) {
            prevState.measurements[0].selected = true;
          }
          this.notificationRef.current.showNotification('Measurement approved successfully!');
          return prevState;
        }))
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  delete = (measurement) => {
    const index = this.state.measurements.findIndex(m => m.name === measurement.name);

    if (index !== -1) {
      //push selected measurement to the database
      fetch('/api/PendingMeasurements/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(measurement)
      })
        .then(response => response.json())
        .then(data => {
          //remove measurement from pending measurements
          this.setState(prevState => ({
            measurements: [
              ...prevState.measurements.slice(0, index),
              ...prevState.measurements.slice(index + 1)
            ]
          }))
        })
        .then(() => this.setState(prevState => {
          // Check if a measurement is already selected
          const alreadySelected = prevState.measurements.some(measurement => measurement.selected);
          if (!alreadySelected && prevState.measurements.length > 0) {
            prevState.measurements[0].selected = true;
          }
          this.notificationRef.current.showNotification('Measurement deleted successfully!');
          return prevState;
        }))
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  openEditModal = (measurement) => {

    this.setState({ currentMeasurement: measurement, editModalIsOpen: true });
  }

  closeEditModal = () => {
    this.setState({ editModalIsOpen: false });
  }

  openTestModal = (measurement) => {

    //fetch test models from the database
    fetch('/api/Models')
      .then(response => response.json())
      .then(data => {
        this.setState({ testModels: data });
      })

    this.setState({ currentMeasurement: measurement, testModalIsOpen: true });
  }

  closeTestModal = () => {
    this.setState({ testModalIsOpen: false, testResults: ''});
  }

  renderMeasurementsTable(measurements, handleClick) {
    const perPage = 10;
    const offset = this.state.currentPage * perPage;
    const currentPageData = measurements.slice(offset, offset + perPage);

    return (
      <div className='row'>

        <div className='col-md-12'>
          <table className='table table-striped' aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Name</th>
                <th>Summary</th>
                <th>Label</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {currentPageData.map(measurement =>
                <tr key={measurement.date}>
                  <td>{this.formatDate(measurement.date)}</td>
                  <td>{measurement.type}</td>
                  <td>{measurement.name}</td>
                  <td>{measurement.summary}</td>
                  <td>{measurement.label}</td>
                  <td>
                    <button className='btn btn-primary me-1' onClick={() => this.handleClick(measurement)}>{measurement.selected ? 'Un-Select' : 'Select'}</button>
                    <button className='btn btn-primary me-1' onClick={() => this.openEditModal(measurement)}>Edit</button>
                    <button className='btn btn-primary me-1' onClick={() => this.accept(measurement)}>Accept</button>
                    <button className='btn btn-primary me-1' onClick={() => this.delete(measurement)}>Delete</button>
                    <button className='btn btn-primary' onClick={() => this.openTestModal(measurement)}>Test</button>

                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            pageCount={Math.ceil(measurements.length / perPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
          />
          {this.renderEditModal()}
          {this.renderTestModal()}
        </div>
      </div>
    );
  }

  renderEditModal() {
    if (!this.state.currentMeasurement) {
      return null;
    }
  
    return (
      <Modal show={this.state.editModalIsOpen} onHide={this.closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Name:</Form.Label>
              <Form.Control type="text" ref={this.nameRef} defaultValue={this.state.currentMeasurement.name} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Summary:</Form.Label>
              <Form.Control type="text" ref={this.summaryRef} defaultValue={this.state.currentMeasurement.summary} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Label:</Form.Label>
              <Form.Control type="text" ref={this.labelRef} defaultValue={this.state.currentMeasurement.label}  required/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Date:</Form.Label>
              <Form.Control type="date" ref={this.dateRef} defaultValue={new Date(this.state.currentMeasurement.date).toISOString().substr(0, 10)} disabled />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  renderTestModal() {
    if (!this.state.currentMeasurement) {
      return null;
    }
  
    return (
      <Modal show={this.state.testModalIsOpen} onHide={this.closeTestModal}>
        <Modal.Header closeButton>
          <Modal.Title>Test Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group className="mb-3">
              <Form.Label>Measurement Name:</Form.Label>
              <Form.Control type="text" ref={this.nameRef} defaultValue={this.state.currentMeasurement.name} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Summary:</Form.Label>
              <Form.Control type="text" ref={this.summaryRef} defaultValue={this.state.currentMeasurement.summary} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Measurement Label:</Form.Label>
              <Form.Control type="text" ref={this.summaryRef} defaultValue={this.state.currentMeasurement.label}  required/> 
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Test Model:</Form.Label>
              <Form.Select aria-label="Test Model"
                value={this.state.selectedModel} 
                onChange={e => this.setState({ selectedModel: e.target.value })}
              >
                {this.state.testModels && this.state.testModels.map(model => <option key={model.id}>{model.name}</option>)}
              </Form.Select>
            </Form.Group>
            <Button variant="primary" onClick={this.TestModel} >
              Test
            </Button>
            <Form.Group className="mb-3">
              <Form.Label>Test Results:</Form.Label>
              <Form.Control as="textarea" rows={3} value={this.state.testResults} readOnly /> 
            </Form.Group>


            
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  TestModel = () => {
    //test the current measurement with the selected model
    const data = { FileName: this.state.currentMeasurement.name, ModelName: this.state.selectedModel, MeasurementType: "pending" };
    fetch('/api/Models/Predict', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        //store test results to be displayed in the modal text area
        this.setState({ testResults: data.message });
      
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    
  }
  handleSubmit = (event) => {
    event.preventDefault();

    const index = this.state.measurements.findIndex(measurement => measurement.name === this.state.currentMeasurement.name);
    if(index === -1) return;

    let updatedMeasurement = this.state.measurements[index];
    updatedMeasurement.summary = this.summaryRef.current.value;
    updatedMeasurement.label = this.labelRef.current.value;
          this.setState(prevState => ({
        measurements: [
          ...prevState.measurements.slice(0, index),
          updatedMeasurement,
          ...prevState.measurements.slice(index + 1)
        ],
        currentMeasurement: updatedMeasurement
      }));
    
    //push updated measurement to the database
    fetch('/api/PendingMeasurements/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedMeasurement)
    })
      .then(() => this.notificationRef.current.showNotification('Measurement updated successfully!'))
      .catch(error => {
        console.error('Error:', error);
      });
    this.closeEditModal();
  }

  handleClick = (measurement) => {
    measurement.selected = !measurement.selected;
    this.setState({ measurements: this.state.measurements });
  }

  render() {
    const selectedMeasurements = this.state.measurements.filter(measurement => measurement.selected);

    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: "IV Curve"
      },
      axisY: {
        title: "Current (uA)",
        zoomEnabled: true
      },
      axisX: {
        title: "Voltage (mV)",
        zoomEnabled: true
      },
      toolTip: {
        shared: true
      },
      data: selectedMeasurements.map(measurement => (
        {
          type: "spline",
          name: measurement.name,
          showInLegend: true,
          dataPoints: measurement.dataPoints
        })),
      legend: {
        cursor: "pointer",
        itemclick: function (e) {
          e.dataSeries.lineThickness = e.dataSeries.lineThickness === 1 ? 5 : 1;
          e.chart.render();
        }
      },
      height: 800
    };

    return (
      <div>
        <Button variant="primary" onClick={this.handleButtonClick}>
          Upload CSV
        </Button>
        <input type="file" accept=".csv" onChange={this.handleFileUpload} ref={this.fileInputRef} hidden />
        <CanvasJSChart options={options} />
        {this.renderMeasurementsTable(this.state.measurements, this.handleClick)}
        <Notification ref={this.notificationRef} />
      </div>
    );
  }

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    fetch('/api/Upload', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        //create a new measurement from data and add it to measurements
        data.selected = true;
        this.setState(prevState => ({
          measurements: [data, ...prevState.measurements]
        }));
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  async populateMeasurementsData() {
    const response = await fetch('api/PendingMeasurements');
    const data = await response.json();

    if (data && data.length > 0) {
      data[0].selected = true;
    }
    this.setState({ measurements: data, loading: false });
  }
}
