import { PendingMeasurements } from "./components/PendingMeasurements";
import { ValidMeasurements } from "./components/ValidMeasurements";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/pending-measurements',
    element: <PendingMeasurements />
  },
  {
    path: '/valid-measurements',
    element: <ValidMeasurements />
  }
];

export default AppRoutes;
