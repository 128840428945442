import React from 'react';
import Alert from 'react-bootstrap/Alert';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: '',
      variant: 'success', // default to success
    };
  }

  showNotification = (message, variant = 'success') => {
    this.setState({
      show: true,
      message: message,
      variant: variant
    });

    setTimeout(() => {
      this.setState({
        show: false
      });
    }, 3000);
  }

  static alertStyle = {
    position: 'fixed',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: '9999',
    width: 'auto',
  };

  render() {
    return (
      this.state.show &&
      <Alert key={this.state.variant} variant={this.state.variant} style={Notification.alertStyle}>
      {this.state.message}
    </Alert>
    );

  }
}

export default Notification;